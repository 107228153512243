/* Form CSS */

.FormTemplate {
    background-color: #fff;
	border-radius: 6px;
	box-shadow: 0px 0px 24px 5px rgba(140, 133, 133, 0.3);
	cursor: default;
	display: block;
	left: 50%;
	max-height: 95vh;
	max-width: 850px;
	overflow: scroll;
	padding: 40px 80px;
	position: fixed;
	text-align: center;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 100%;
	z-index: 999;
}

.FormTemplate-large {
    max-width: 1500px;
}

.FormTemplate-small {
    max-width: 550px;
}


@media only screen and (max-width: 900px) {

	.FormTemplate {
		top: 2vh;
		transform: translateX(-50%);
	}
}

@media only screen and (max-width: 750px) {

	.FormTemplate {
		padding: 40px;
	}
}

@media only screen and (max-width: 450px) {

	.FormTemplate {
		left: 0;
		overflow: scroll;
		padding: 40px 15px;
		position: fixed;
		transform: none;
	}
}

@media only screen and (max-width: 360px) {

	.FormTemplate {
		padding: 40px 10px;
	}
}
