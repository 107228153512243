* {
	padding: 0;
	margin: 0;
	list-style-type: none;
	box-sizing: border-box;
	line-height: 1;
}

body {
	background-color: #84f48485;
	color: #0f131a;
	font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 16px;
}

img {
	height: auto;
	width: 100%;
}

/* Components */

@keyframes expandAndMove {
	0% {
		opacity: 0;
		transform: translateY(-50px) translateX(-50%) scale(0);
	}
	100% {
		opacity: 1;
		transform: translateY(0) translateX(-50%) scale(1);
	}
}

.message {
	color: #800080;
	font-size: 25px;
	left: 50%;
	max-width: max-content;
	opacity: 0;
	padding: 0 10px;
	position: absolute;
	text-align: center;
	text-shadow: 2px 2px 5px turquoise;
	top: 48px;
	transform: translateY(0) translateX(-50%) scale(0);
	width: 100%;
}

.message.show {
	animation: expandAndMove 0.5s ease-out forwards;
}

.game {
	cursor: url('../assets/bite.png') 0 5, auto;
	height: 100vh;
	overflow: hidden;
	position: relative;
	width: 100vw;
}

.game__header {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 40px;
}

.game__header__stats {
	align-items: center;
	cursor: default;
	display: flex;
	max-width: 150px;
	justify-content: space-between;
	width: 100%;
}

.game__burger, 
.game__level {
	align-items: center;
	display: flex;
}

.game__burger__icon, 
.game__level__icon {
	background-size: contain;
	display: inline-block;
	height: 35px;
	width: 35px;
}

.game__burger__icon {
	background-image: url('../assets/burger.svg');
}

.game__level__icon {
	background-image: url('../assets/speed.svg');
}

.game__header__buttons {
	align-items: center;
	display: flex;
	justify-content: space-between;
	/* max-width: 160px;
	width: 100%; */
}

.game__music {
	background-image: url('../assets/music.svg');
	background-repeat: no-repeat;
	background-size: contain;
	display: inline-block;
	height: 35px;
	margin-right: 20px;
	transform: translateY(2px);
	width: 35px;
}

.game__music__line {
	background-color: #fff;
	height: 2px;
	transform: rotate(135deg) translateX(10px) translateY(-13px);
	width: 34px;
}

.game__title {
	color: #FFC0CB;
	font-family: 'Protest Riot', sans-serif;
	font-size: 100px;
	left: 50%;
	line-height: 1.5;
	max-width: 1000px;
	position: absolute;
	text-align: center;
	top: 30vh;
	transform: translateX(-50%);
	width: 100%;
}

.game__loserMessage {
	color: #800080;
	font-family: 'Protest Riot', sans-serif; 
	font-size: 36px; 
	margin-bottom: 30px;
}

.game__over {
	color: #FF0000;
	font-size: 80px; 
	font-weight: 700;
	margin-bottom: 30px;
}

.game__score {
	color: #800080;
	font-size: 32px;
	margin-bottom: 40px;
}

.game__leaderboardMessage {
	font-size: 21px;
	margin-bottom: 10px;
}

.game__form {
	display: flex;
	align-items: center;
	margin: 0 auto;
	width: max-content;
}

.game__leaderboard__title {
	color: #800080;
	font-size: 30px;
	margin: 30px 0 20px 0;
}

.game__leaderboard {
	max-width: 360px;
	margin: 0 auto 30px auto;
}

.game__leaderboard__row {
	display: grid;
	font-size: 18px;
	font-weight: 600;
	grid-template-columns: 50px auto 30px;
	margin-bottom: 5px;
}

.game__icons {
	align-items: center;
	display: flex;
	margin: 20px auto 0 auto;
	width: max-content;
}

.game__icon {
	background-repeat: no-repeat;
	background-size: contain;
	display: inline-block;
	height: 25px;
	margin: 0 5px;
	width: 25px;
}

.game__fb {
	background-image: url('../assets/facebook.svg');
}

.game__x {
	background-image: url('../assets/twitter.svg');
	height: 26.5px;
	width: 26.5px;
}

.resultMessage {
	line-height: 1.8;
	margin-bottom: 20px;
}


/* text */

a {
	text-decoration: none;
	transition: all 0.35s;
}

h1 {
	font-size: 28px;
	font-weight: 500;
}

h3 {
	font-size: 21px;
	font-weight: 500;
	line-height: 1.5;
}

p {
	font-size: 16px;
	line-height: 1.5;
}

label {
	color: #253551;
	font-size: 15px;
	font-weight: 500;
}

.light {
	font-weight: 400;
}

.medium {
	font-weight: 500;
}

.semi-bold {
	font-weight: 600;
}

.bold {
	font-weight: 700;
}

.opacity {
	opacity: 65%;
}

.centre {
	text-align: center;
}

.left {
	text-align: left;
}

.right {
	text-align: right;
}

.italic {
	font-style: italic;
}

.underline {
	text-decoration: underline;
}

.block {
	display: block;
}

.inline-block {
	display: inline-block;
}

.inline {
	display: inline;
}

.flex {
	display: flex;
}

/* Colours */

.black {
	color: #000;
}

.blue {
	color: #253551;
}

.green {
	color: #6DA726;
}

.grey {
    color: #808080;
}

.light-blue {
	color: #0454A8;
}

.light-grey {
	background-color: #F6F8FB;
}

.orange {
	color: #FE5100;
}

.pink {
	color: #FF38F4;
}

.purple {
	color:#800080;
}

.red {
	color: #FF0000;
}

.white {
	color: #fff;
}

.yellow {
	color: #D87F2D;
}

/* Forms */

input {
	border: 1px solid #bbb;
	border-radius: 5px;
	font-size: 20px;
	margin-right: 20px;
	outline: none;
	padding: 11px 20px;
}

.form__title {
	margin-bottom: 40px;
}

.form__flexContainer {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.form__grid-2 {
	display: grid;
	grid-template-columns: repeat(2, calc(50% - 10px));
	column-gap: 20px;
}

.form__grid-3 {
	column-gap: 30px;
	display: grid;
	grid-template-columns: repeat(3, calc(33.3333% - 20px));
	row-gap: 30px;
	margin-bottom: 30px;
	max-width: 600px;
}

.form__label {
	display: flex;
	margin-bottom: 8px;
}

.form__link {
	color: #1976d2;
	cursor: pointer;
	margin-bottom: 20px;
}

.form__text {
	margin: 10px 0;
}

.form__tabs {
	border-bottom: 1px solid #D5D5D5;
	margin-bottom: 20px;
}

.form__tabs__tab {
	cursor: pointer;
	display: inline-block;
	margin-right: 30px;
	padding-bottom: 10px;
	width: max-content;
}

.form__tabs__tab.active {
	border-bottom: 1px solid #FE5100;
}

.form__slider {
	margin: 40px 0;
}

::-webkit-input-placeholder { /* Edge */
	font-family: 'Poppins', sans-serif !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
	font-family: 'Poppins', sans-serif !important;
}

::placeholder {
	font-family: 'Poppins', sans-serif !important;
}

.form__gallery {
	display: flex;
	margin-top: 60px;
}

.form__gallery__item {
	align-items: center;
	background-color: #F3F5F7;
	border: 1px solid #E0E5EA;
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	height: 165px;
	justify-content: end;
	margin: 0 40px 40px 0;
	max-width: 165px;
	padding-bottom: 37px;
	transition: all 0.25s;
	width: 100%;
}

.form__gallery__item:hover {
	opacity: 0.7;
}

.form__gallery__item__icon {
	background-size: cover;
}

.form__gallery__item__text {
	color: #2A3042;
	font-size: 15px;
	font-weight: 400;
	margin-top: 20px;
}

/* Layout */

.card {
	border: 1px solid #DCDFE2;
    border-radius: 4px;
    box-shadow: 0px 0px 9px rgba(0,0,0, 0.080556);
    margin-bottom: 50px;
	position: relative;
    width: 100%;
}

.table {
	margin-bottom: 60px;
    width: 100%;
}

.tr {
	align-items: center;
    border-left: 1px solid #E1E1E1;
    border-right: 1px solid #E1E1E1;
    border-top: 1px solid #E1E1E1;
    display: grid;
}

.tr:last-child {
    border-bottom: 1px solid #E1E1E1;
}

.th {
    color: #1D1F23;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.3;
    padding: 0 5px;
}

.td {
    color: #1D1F23;
    font-size: 14px;
    font-weight: 500;
}

.clickable {
	cursor: pointer;
	transition: all 0.3s;
}

.clickable:hover {
	opacity: 0.7
}

/* Media queries */

@media only screen and (max-width: 950px) {

	.message {
		top: 100px;
	}

	.form__grid-2, .form__flexContainer {
		display: block;
	}
	
}

@media only screen and (max-width: 700px) {

	.game__title {
		font-size: 80px;
		top: 27vh;
	}

	.flyingObject {
		max-width: 200px;
	}
}

@media only screen and (max-width: 520px) {

	.game__header {
		padding: 20px;
	}

	.game__title {
		font-size: 60px;
	}

	.flyingObject {
		max-width: 180px;
	}
}


@media only screen and (max-width: 500px) {

	.game__form {
		display: block;
	}

	.game__form input {
		display: block;
		margin: 0 0 20px 0;
	}
}


@media only screen and (max-width: 386px) {

	.game__header {
		padding: 10px;
	}
}

@media only screen and (max-width: 360px) {

	.game__header__stats {
		max-width: 140px;
	}

	.game__header__buttons {
		max-width: 150px;
	}
}

@media only screen and (max-width: 326px) {

	.game__header {
		padding: 5px;
	}

}

@media only screen and (max-width: 314px) {

	.game__header {
		padding: 5px;
	}

	.game__header__stats {
		max-width: 130px;
	}

	.game__header__buttons {
		max-width: 140px;
	}

	.game__music {
		margin-right: 10px;
	}
}
