.Button {
	align-items: center;
	border:  0;
	border-radius: 4px;
	display:  flex;
	color:  #FFF;
	cursor: pointer;
	font-family: inherit;
	font-size: 18px;
	font-weight:  500;
	outline:  0;
	padding:  15px 30px;
	text-align: center;
	transition: all 0.3s;
	max-width:  max-content;
	width: 100%;
}

.Button:hover {
	opacity:  0.8;
}

.Button--size-small {
	padding:  10px 15px;
	font-size:  16px;
	font-weight:  500;
}

.Button--size-large {
	display: block;
	font-size:  18px;
	font-weight:  500;
	max-width: 100%;
	padding:  18px 80px;
	width: 100%;
}

.Button--type-rounded {
	border-radius: 50px;
}

.Button.disabled {
	background-color: rgba(0, 0, 0, 0.12);
	color: rgba(0, 0, 0, 0.26);
	cursor: default;
}

.Button.disabled:hover {
	opacity: 1;
}

.Button.startIcon {
	padding: 15px 24px 15px 20px;
}

/* Colour Variants
-----------------------------------------------*/

.Button--colour-blue, .Button--colour-default {
	background-color:  #1976d2;
}

.Button--colour-white {
	background-color:  #FFF;
}

.Button--colour-orange {
	background-color:  #EE7523;
}

.Button--colour-green {
	background-color:  #5DA12D;
}

.Button--colour-purple {
	background-color:  #800080;
}

.Button--colour-red, .Button--type-delete {
	background-color:  #C33C3C;
}

/* types */

.Button--type-outlined {
	background-color: #fff;
	border: 1px solid #1565c0;
	color: #1565c0;
}

.Button--type-outlined.Button--colour-white {
	background-color: transparent;
	border: 1px solid #FFF;
	color: #FFF;
}

.Button--type-outlined.Button--colour-orange {
	background-color: #fff;
	border: 1px solid #EE7523;
	color: #EE7523;
}

.Button--type-outlined.Button--colour-green {
	background-color: #fff;
	border: 1px solid #5DA12D;
	color: #5DA12D;
}

.Button--type-outlined.Button--colour-red {
	background-color: #fff;
	border: 1px solid #C33C3C;
	color: #C33C3C;
}

.Button--type-delete {
	opacity:  0.5;
	margin-top:  120px;
}


/* icons */

.Button__icon {
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
	margin-right: 8px;
	height: 18px;
	width: 18px;
}

@media only screen and (max-width: 380px) {
	
	.Button.responsive {
		padding: 10px 15px;
	}
}